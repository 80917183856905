<template>
  <div>Home</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('cart', ['ADD_TO_CART']),
  },

  mounted() {
    this.ADD_TO_CART()
  },
}
</script>
